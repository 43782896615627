<template>
    <div>
        <TinyNavigation :prev="{name: 'matches'}" class="fixed pt-6 px-2 z-10" />

        <Modal v-model:isOpen="isCloneModalOpen">
            <div>
                <div>
                    <h3 class="font-bold text-center text-2xl text-white" id="modal-headline">
                        {{ t('clone.button') }}
                    </h3>
                    <div class="text-white mt-8">
                        {{ t('clone.desc') }}
                    </div>
                </div>
            </div>
            <div class="mt-12">
                <span class="flex w-full rounded-md shadow-sm">
                    <button @click="isCloneModalOpen = false" type="button" class="flex-1 button-transparent mr-2">
                        {{ t('clone.cancel') }}
                    </button>

                    <button @click="cloneMatch()" type="button" class="flex-1 button ml-2">
                        {{ t('clone.confirm') }}
                    </button>
                </span>
            </div>
        </Modal>

        <template v-if="!match_not_found">
            <div class="px-2 pt-20 pb-10">
                <div>
                    <Video v-if="video?.path" :video-url="video?.path" :thumbnail="video?.thumbnail"/>
                </div>
                <div class="bg-gray-900 rounded-b p-6">
                    <div class="mb-10">
                        <div class="flex justify-between align-top">
                            <div>
                                <h1 class="text-gray-100 font-semibold text-2xl mb-2">{{ video?.title }}</h1>
                                <h3 class="text-gray-100 font-normal text-base mb-2">{{ video?.description }}</h3>
                                <p class="text-gray-100 font-normal text-xs">{{ t('created_at', {date: dateToHumanReadable(match.created_at)}) }}</p>
                            </div>
                            <div>
                                <router-link :to="{name: 'match.edit'}" v-if="match.read_only === false && !match.team" class="button-transparent button-small">{{ t('edit') }}</router-link>
                            </div>
                        </div>
                    </div>

                    <button v-if="match.team" class="button" @click="isCloneModalOpen = true">
                        {{ t('clone.button') }}
                    </button>

                    <div class="flex-auto" v-else>
                        <div class="sm:grid sm:gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-items-center">
                            <match-property class="w-full inline-block mb-4 sm:mb-0" :type="t('position')" :value="match.position" />
                            <match-property class="w-full inline-block mb-4 sm:mb-0" :type="t('age')" :value="match.age" />

                            <template v-for="(answer, key) in shortAttributes" :key="key"> 
                                <match-property 
                                    :type="translateKey(key)" 
                                    :value="answer"
                                    class="w-full inline-block mb-4 sm:mb-0"    
                                />
                            </template>
                        </div>
                        <div class="mt-5">
                            <template v-for="(answer, key) in longAttributes" :key="key">
                                <match-property class="w-full lg:w-2/3 inline-block" :type="translateKey(key)" :value="answer" prop="large" />
                            </template>
                        </div>
                    </div>

                    <p class="text-white font-normal text-sm">{{ match.match }}</p>
                </div>
            </div>
        </template>
        <template v-else>
            <p class="text-white px-12 pt-10">{{ t('match_not_found') }}</p>
        </template>
    </div>
</template>

<script>
import Modal from "@/wrappers/partials/Modal";
import TinyNavigation from "@/components/TinyNavigation";
import { getMatch, cloneMatch } from "@/helpers/api/MatchHelper";
import messages from "@/helpers/storage/MessageHelper";
import Video from "@/components/Video";
import MatchProperty from "@/components/match/MatchProperty";
import { dateToHumanReadable } from "@/helpers/DateHelper"
import { getMatchQuestions } from "@/helpers/api/MatchQuestionHelper"
import useI18n from "@/modules/globalI18n";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";

export default {
    name: "MatchShow",
    components: { TinyNavigation, Video, MatchProperty, Modal },
    props: ['id'],
    setup() {
        return useI18n({
            viewPrefix: "MatchShow"
        });
    },
    data(){
        return {
            isCloneModalOpen: false,
            match_not_found: false,
            match: {}
        }
    },
    created(){
        this.getMatch();
        this.getDynamicQuestions();
    },
    mounted() {
        this.getDynamicQuestions();
    },
    watch: {
        id() {
            this.getMatch();
        }
    },
    computed: {
        video() {
            if(this.isEmptyObject(this.match)){
                return undefined;
            }
            return this.match?.video;
        },
        shortAttributes() {
            if (!this.match?.questions || !this.match_questions) {
                return {}
            }
            const keysOfLongAttrs = this.match_questions
                        .filter(question => question.type === "text")
                        .map(question => question.key);

            
            const attributes = Object.assign({}, this.match.questions);

            keysOfLongAttrs.forEach(key => {
                delete attributes[key]
            })

            return attributes
        },
        longAttributes() {
            if (!this.match?.questions || !this.match_questions) {
                return {}
            }
            const keysOfShortAttrs = this.match_questions
                        .filter(question => question.type !== "text")
                        .map(question => question.key);

            
            const attributes = Object.assign({}, this.match.questions);

            keysOfShortAttrs.forEach(key => {
                delete attributes[key]
            })

            return attributes
        }
    },
    methods: {
        getMatch() {
            getMatch(this.id, true)
                .then((data) => {
                    console.log("getMatch data", data, data.match);
                    this.match = data.match;
                })
                .catch(err => {
                    if (err.status === 404 || err.status === 403){
                        this.match_not_found = true;
                    } else {
                        messages.error(this.t('messages.something_went_wrong', {error: err.message}));
                    }
                });
        },
        async getDynamicQuestions() {
            this.match_questions = await getMatchQuestions();
        },
        isEmptyObject(obj) {
            if(!obj) {
                return false;
            }
            return obj.constructor === Object && Object.keys(obj).length === 0;
        },
        async cloneMatch() {
            this.isCloneModalOpen = false;
            startSpinner();
            try
            {
                const data = await cloneMatch(this.match.id);
                this.$router.push({name: 'match.show', params: { id: data.match.id }});
                messages.success(this.t('messages.clone_success'));
            } catch(err) {
                messages.error(this.t('messages.something_went_wrong', { error: err.response?.data?.message }));
            } finally {
                stopSpinner();
            }
        },
        translateKey(key) {
            return this.match_questions.find(question => question.key === key)?.question ?? ""
        },
        dateToHumanReadable
    }
}
</script>

<style  scoped>
</style>
<template>
  <div class="bg-black rounded py-2 px-4 flex items-center justify-between" v-bind:class="prop === 'large'? 'min-w-full min-h-full flex-col' : 'flex-row h-10' ">
    <p class="text-button-grey text-sm inline-block" v-bind:class="prop === 'large'? 'w-full' : '' ">{{ type }}</p>
    <p class="text-white text-sm font-medium float-right" v-bind:class="prop === 'large'? 'min-w-full min-h-full whitespace-pre-wrap': '' ">{{ value }}</p>
  </div>
</template>

<script>
export default {
  name: "MatchProperty",
  props: ['type', 'value', 'prop'],
}
</script>

<style scoped>

</style>
